html, body {
  background-color: black;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: white;
}

#root {
  width: 100%;
  height: 100%;
}

.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Adds padding to the left and right of the navbar */
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: black; /* Ensure the navbar background is opaque */
}

.custom-nav-link {
  padding-left: 20px; /* Adds padding to the left of the Home button */
  color: white;
}

.custom-button {
  background-color: red;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
}

.custom-button:hover {
  background-color: darkred;
  color: white;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.contact-email {
  margin-left: 20px; /* Adds spacing to the left of the email */
  padding-right: 20px; /* Adds padding to the right of the email */
  color: white;
}

.contact-phone {
  padding-left: 20px; /* Adds padding to the left of the phone number */
  margin-right: 20px; /* Adds spacing to the right of the phone number */
  color: white;
}

.contact-info a {
  color: red; /* Makes email and phone number red */
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline; /* Adds underline on hover */
}

.custom-brand {
  padding-right: 20px; /* Adds padding to the right of the logo */
}

.custom-brand img {
  margin-left: 10px;
}

.build-shed-button {
  margin: 0 20px; /* Adds margin between the button and other elements */
  background-color: red;
  border-color: red;
  color: white;
}

.build-shed-button:hover {
  background-color: darkred;
  border-color: darkred;
}

.navbar-toggler-icon {
  color: white;
  outline: white;
}

@media (max-width: 768px) {
  .custom-navbar {
    flex-direction: column;
    padding: 10px;
  }

  .contact-info {
    order: 1; /* Ensures contact info is always visible */
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .contact-email, .contact-phone {
    padding: 5px 0;
  }

  .build-shed-button {
    order: 2; /* Ensures build shed button is always visible */
    width: 100%;
    margin-top: 10px;
  }

  .custom-brand {
    order: 3; /* Ensures logo is always visible */
  }
}
