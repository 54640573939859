.hero-section {
    background-color: black;
    color: white; /* Ensure text is readable on a black background */
    padding: 20px;
  }
  
  .card {
    background-color: #333; /* Dark background for the cards */
    color: white;
  }
  
  .card-img-top {
    border-bottom: 1px solid #444; /* Optional: Add a border between the image and the card body */
  }
  
  .card-title {
    color: white;
  }
  
  .card-text {
    color: #ccc; /* Slightly lighter color for the text */
  }
  
  .btn-primary {
    background-color: red;
    border-color: red;
  }
  
  .btn-primary:hover {
    background-color: darkred;
    border-color: darkred;
  }
  