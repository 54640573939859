.info-section {
    background-color: black;
    color: white;
    padding: 20px;
  }
  
  .info-card {
    background-color: #333; /* Dark background for the cards */
    border: 1px solid #444;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .info-card .icon {
    color: white; /* Customize icon color */
  }
  
  .info-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
  
  .info-card p {
    font-size: 1rem;
    color: #ccc; /* Slightly lighter color for the text */
  }
  