.about-section {
    background-color: black;
    color: white;
    padding: 20px;
  }
  
  .about-card-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .about-section h2 {

    font-size: 2rem;
    font-weight: bold;
    color: white;
  }
  
  .about-section p {
    font-size: 1rem;
    color: #ccc; /* Slightly lighter color for the text */
  }
  