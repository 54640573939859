html, body {
    background-color: black;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: white;
  }
  
  #root {
    width: 100%;
    height: 100%;
  }
  
  .large-card-section {
    background-color: black;
    color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .large-card-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .large-card-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .large-card-section ul {
    list-style-type: none;
    padding: 0;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .large-card-section ul li {
    margin-bottom: 10px;
  }
  
  .large-card-section p {
    font-size: 1rem;
    color: white;
  }
  
  .large-card-section .btn-primary {
    background-color: red;
    border-color: red;
  }
  
  .large-card-section .btn-primary:hover {
    background-color: darkred;
    border-color: darkred;
  }
  